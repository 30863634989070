<template>
  <div class="pbz-font d-flex flex-column justify-content-center">
    <div class="d-flex m-3">
      <h1 class="h3">
        Credit Notes
      </h1>
      <div
        v-if="$store.state.user.restriction_base?.toLowerCase() !== 'business' && $store.state.user.role_name?.toLowerCase() !== 'principle'"
        class="ml-auto d-flex"
      >
        <a-button
          class="create-button d-flex align-items-center h-35px p-3"
          style="font-size: 12px"
          @click="handleVisible(true)"
        >
          Create Credit Notes
        </a-button>
      </div>
    </div>

    <div
      v-if="$store.state.user.restriction_base?.toLowerCase() === 'business' && $store.state.user.role_name?.toLowerCase() === 'principle'"
      style="width: 350px"
    >
      <a-select
        placeholder="Pilih Warehouse"
        show-search
        style="width: 100%"
        option-filter-prop="label"
        @change="handleWarehouse"
      >
        <a-select-option
          v-for="(item, index) in warehouseData"
          :key="index"
          :value="item.id"
          :label="item.name"
        >
          <div>
            {{ item.name }}
          </div>
        </a-select-option>
      </a-select>
    </div>

    <div class="mt-4">
      <a-tabs :default-active-key="$route.query.status || 'ALL'" class="pca-tab-store" @change="changeTab">
        <a-tab-pane key="REQUESTED,APPROVED,CANCELED,REJECTED" :tab="'Semua'" />
        <a-tab-pane key="REQUESTED" :tab="'Pengajuan'" />
        <a-tab-pane key="APPROVED" :tab="'Disetujui'" />
        <a-tab-pane key="REJECTED" :tab="'Ditolak'" />
        <a-tab-pane key="CANCELED" :tab="'Dibatalkan'" />
      </a-tabs>
    </div>

    <div class="retur-list-container d-flex flex-column justify-content-center">
      <div class="content">
        <a-table
          class="payment-list mt-3 mb-2 bg-white"
          :data-source="dataListCreditNotes"
          :row-key="(record, index) => index"
          :columns="columns"
          :loading="loading"
          :pagination="false"
        >
          <template slot="return_number" slot-scope="text">
            <div
              class="text-blue-2"
              style="font-weight: bold"
            >
              {{ text }}
            </div>
          </template>
          <span slot="status" slot-scope="status">
            <a-tag
              :key="status"
              :color="status === 'REQUESTED' ? 'blue' : 
                status === 'APPROVED' ? 'green' : 
                status === 'CANCELED' ? 'pink' : 'red'"
            >
              {{ getLabel(status) }}
            </a-tag>
          </span>
          <div slot="action" slot-scope="record" class="mr-3" @click="handleModal(true, record)">
            <Eye class="mx-3" style="cursor: pointer" />
          </div>
        </a-table>
        <Pagination
          class="text-right"
          :total="pagination.total_items"
          :page-size="pagination.size"
          :total-length-per-page="dataListCreditNotes?.length"
          :default-page="page"
          @changePage="changePage"
        />
      </div>
    </div>

    <a-modal
      center
      :width="600"
      :footer="null"
      :closable="false"
      :visible="visibleRequest"
      title="Form Request Credit Notes"
    >
      <a-form-model ref="ruleForm" :model="form">
        <div class="d-flex mt-2">
          <a-col :span="6" style="color: #6C757D">
            Nama Distributor
          </a-col>
          <a-col :span="9" style="color: #212529">
            {{ dataSeller?.name }}
          </a-col>
          <!-- <a-col :span="4" style="color: #6C757D">
            No. Credit Notes
          </a-col>
          <a-col :span="6" style="color: #212529">
            CN/27101034/00001
          </a-col> -->
        </div>
        <div class="d-flex mt-2 mb-5">
          <a-col :span="6" style="color: #6C757D">
            Tanggal
          </a-col>
          <a-col :span="8" style="color: #212529">
            {{ $moment(new Date()).format('DD MMMM YYYY') }}
          </a-col>
        </div>
        <a-skeleton>
          <a-form-model-item
            label="Input Penarikan Point"
            prop="amount"
          >
            <InputNumber
              v-model="form.coinValue"
              size="large"
              :placeholder="$t('withdrawal.withdrawal_amount')"
            />
            <div class="ant-form-explain mt-1" style="color: #6C757D">
              Anda akan mendapatkan sejumlah Rp ..........
            </div>
          </a-form-model-item>
          <span class="note">Term & Condition :</span>
          <br />
          <span class="note">* Anda dapat melakukan claim Minimum .........point & maksimum ..............point</span>
          <br />
          <span class="note">* Credit Note dapat direquest maksimum ................. kali dalam .....................</span>
          <br />
          <span class="note">* Proses akan dilakukan maksimal  2x24 Jam</span>
        </a-skeleton>
        <div class="mt-4 text-center">
          <a-button
            type="primary"
            size="large"
            class="mr-1"
            ghost
            :loading="loading"
            @click="handleVisible(false)"
          >
            {{ $t('utils.cancel') }}
          </a-button>
          <a-button
            type="primary"
            size="large"
            :loading="loading"
            @click="onConfirmRequest"
          >
            Submit
          </a-button>
        </div>
      </a-form-model>
    </a-modal>

    <ModalDetail 
      :show-modal="showModal"
      :type="type"
      :row="rowData"
      :loading="loading"
      :downloading="downloading"
      @handleModal="handleModal"
      @handleConfirm="handleConfirm"
      @handleReject="handleReject"
      @handleCancel="handleCancel"
    />
  </div>
</template>

<script>
  import Eye from '@/components/Icons/Eye.vue'
  import ModalDetail from '@/views/finance/credit-notes/ModalDetail.vue'
  import list from '@/composable/credit-notes/list'
  import Pagination from '@/components/Pagination/index.vue'
  import InputNumber from '@/components/Input/InputNumber.vue'

export default {
  name: 'ListRetur',
  components: {
    Eye,
    ModalDetail,
    Pagination,
    InputNumber,
  },
  setup() {
    const {
      dataListCreditNotes,
      loading,
      warehouseData,
      pagination,
      page,
      dataSeller,
      showModal,
      changePage,
      handleWarehouse,
      getListCreditNotes,
      handleShowModal,
      handleConfirm,
      handleProcess,
      handleReject,
      handleCancel,
    } = list()

    return {
      dataListCreditNotes,
      loading,
      warehouseData,
      pagination,
      page,
      dataSeller,
      showModal,
      changePage,
      handleWarehouse,
      getListCreditNotes,
      handleShowModal,
      handleConfirm,
      handleProcess,
      handleReject,
      handleCancel,
    }
  },
  data: function() {
    return {
      form: {
        coinValue: '',
      },
      columns: [
        {
          title: 'No. Credit Notes',
          dataIndex: 'cn_number',
          key: 'cn_number',
          ellipsis: true,
          class: 'text-center',
          scopedSlots: { customRender: 'return_number' },
        },
        {
          title: 'Distributor',
          dataIndex: 'seller_name',
          key: 'seller_name',
          width: '25%',
          class: 'text-center',
          ellipsis: true,
        },
        {
          title: 'Tanggal Request',
          dataIndex: 'created_at',
          key: 'created_at',
          class: 'text-center',
          customRender: value => {
            const time =
              value && this.$moment(value).format('DD MMM YYYY')
            if (time) return time
            else return '-'
          },
        },
        {
          title: 'Penarikan',
          dataIndex: 'coin_value',
          key: 'coin_value',
          class: 'text-center',
          width: '25%',
          ellipsis: true,
          customRender: value => {
            const temp = value && this.toCurrency(value)
            if (temp) return temp
            else return '-'
          },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          class: 'text-center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '',
          key: 'action',
          dataIndex: '',
          class: 'text-center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      type: '',
      rowData: {},
      downloading: false,
      visibleRequest: false,
    }
  },
  watch: {
    showModal: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value === false) {
          this.getListCreditNotes()
        }
      },
    },
  },
  methods: {
    onConfirmRequest() {
      if (!this.form.coinValue) {
        return this.$notification.error({
            message: 'Gagal',
            description: 'Jumlah Koin Wajib Diisi',
          })
      }
      const payload = {
        seller_id: this.dataSeller.seller_id,
        seller_name: this.dataSeller.name,
        channel_id: Number(this.$route.params.id),
        coin_value: parseInt(this.form.coinValue.replace('.','')),
        currency: "IDR",
      }
      this.handleProcess(payload)
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    handleModal(param, row) {
      this.handleShowModal(param)
      this.rowData = row
    },
    handleVisible(param) {
      if (param === false) {
        this.form.coinValue = ''
      }
      this.visibleRequest = param
    },
    changeTab(key) {
      let temp = { ...this.$route.query, status: key }
      this.$router.push({
        query: temp,
      })
    },
    getLabel(label) {
      if (label === 'REQUESTED') {
        return 'Pengajuan'
      } else if (label === 'APPROVED') {
        return 'Disetujui'
      } else if (label === 'REJECTED') {
        return 'Ditolak'
      } else if (label === 'CANCELED') {
        return 'Dibatalkan'
      }
    },
  },

}
</script>

<style lang="scss">
.retur-list-container {
  
  .content {
    width: 100%;
    padding: 1rem;
    // border: 1px solid #999;
    border-radius: 5px;
    border-top-left-radius: 0;

    .search-box {
    width: 228px;
    }
    .list {
    padding: 1rem;
    // border: 1px solid #999;
    border-radius: 5px;
    }
    .header-list {
      font-weight: 900;
      background: #E7ECEF;
      border-radius: 5px ;
      padding: 10px;
    }
    .data-list {
      padding: 5px 0;
      margin: 10px 0;
      background: #fff;
      border-radius: 5px ;
    }

    // ANT DESIGN
    .ant-collapse {
      border: none
    }
  }
}
.pca-tab-store {
  .ant-tabs-bar {
    // background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}
.create-button {
  color: #fff !important;
  background: #ff0854 !important;
}
.note {
  font-size: 12px;
  font-style: italic;
}
</style>