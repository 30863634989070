import { reactive, toRefs, onMounted, watch, computed } from 'vue'
import { notification } from 'ant-design-vue'
import store from '@/store'
import route from '@/router'
import debounce from 'lodash/debounce'
import { getSeller, getCreditNotes, crudCreditNote } from '@/api/channels/distributor'
import { getWarehouseList } from '@/api/warehouse'

export default () => {
  const routeName = route.app._route.name
  const state_ = reactive({
    dataListCreditNotes: [],
    warehouseData: [],
    dataSeller: {},
    businessId: '',
    selectedWarehouse: '',
    pagination: {
      size: 10,
      total_items: 0,
    },
    pages: 1,
    loading: false,
    showModal: false,
  })

  onMounted(() => {
    getBusinessId()
    getWarehouse()
    getListCreditNotes()
  })

  watch(
    () => route.app._route.query,
    () => {
      if (route.app._route.name == routeName) {
        getListCreditNotes()
      }
    },
  )
  
  watch(
    () => state_.selectedWarehouse,
    () => {
        getListCreditNotes()
    },
  )

  const page = computed(() => {
    return +route.app._route.query.page || 1
  })

  const handleShowModal = (param) => {
    state_.showModal = param
  }

  const getBusinessId = async () => {
    state_.businessId = store.state.user.businessList?.[0]?.business_id
    return await store.state.user.businessList?.[0]?.business_id
  }

  const getWarehouse = async () => {
    if (store.state.user.restriction_base.toLowerCase() == 'business') {
      await getWarehouseList({ business_id: route.app._route.query.business_id })
      .then(({ data }) => state_.warehouseData = data.data)
      .catch(err => console.error(err))
    }
  }

  const handleConfirm = async (cn_id) => {
    state_.loading = true
    const businessId = route.app._route.query.business_id || (await getBusinessId())
    await crudCreditNote({
      business_id: businessId,
      type: 'approve',
      data: { id: cn_id },
    })
    .then(({ data }) => {
      if (data.message_code === "ALL.ALL.OK") {
        notification.success({
          message: 'Berhasil',
          description: 'Data Berhasil Diperbaharui',
        })
        state_.loading = false
        handleShowModal(false)
      } else {
        notification.error({
          message: 'Gagal',
          description: data.message,
        })
        state_.loading = false
      }
    })
    .catch(err => {
      console.error('errornya', err)
      notification.error({
        message: 'Gagal',
        description: err?.response?.data?.message,
      })
      state_.loading = false
    })
  }

const handleProcess = async (payload) => {
    state_.loading = true
    const businessId = route.app._route.query.business_id || (await getBusinessId())
    await crudCreditNote({
      business_id: businessId,
      type: 'request',
      data: payload,
    })
    .then(({ data }) => {
      if (data.message_code === "ALL.ALL.OK") {
        notification.success({
          message: 'Berhasil',
          description: 'Data Berhasil Ditambahkan',
        })
        state_.loading = false
        handleShowModal(false)
      } else {
        notification.error({
          message: 'Gagal',
          description: data.message,
        })
        state_.loading = false
      }
    })
    .catch(err => {
      console.error(err)
      notification.error({
        message: 'Gagal',
        description: err?.response?.data?.message,
      })
      state_.loading = false
    })
  }

  const handleReject = async (cn_id, body) => {
    state_.loading = true
    const businessId = route.app._route.query.business_id || (await getBusinessId())
    const payload = {
      id: cn_id,
      note: body,
    }
    await crudCreditNote({
      business_id: businessId,
      type: 'reject',
      data: payload,
    })
    .then(({ data }) => {
      if (data.message_code === "ALL.ALL.OK") {
        notification.success({
          message: 'Berhasil',
          description: 'Data Berhasil Diperbaharui',
        })
        state_.loading = false
        handleShowModal(false)
      } else {
        notification.error({
          message: 'Gagal',
          description: data.message,
        })
        state_.loading = false
      }
    })
    .catch(err => {
      console.error(err)
      notification.error({
        message: 'Gagal',
        description: err?.response?.data?.message,
      })
      state_.loading = false
    })
  }

  const handleCancel = async (cn_id, body) => {
    state_.loading = true
    const businessId = route.app._route.query.business_id || (await getBusinessId())
    const payload = {
      id: cn_id,
      note: body,
    }
    await crudCreditNote({
      business_id: businessId,
      type: 'cancel',
      data: payload,
    })
    .then(({ data }) => {
      if (data.message_code === "ALL.ALL.OK") {
        notification.success({
          message: 'Berhasil',
          description: 'Data Berhasil Diperbaharui',
        })
        state_.loading = false
        handleShowModal(false)
      } else {
        notification.error({
          message: 'Gagal',
          description: data.message,
        })
        state_.loading = false
      }
    })
    .catch(err => {
      console.error(err)
      notification.error({
        message: 'Gagal',
        description: err?.response?.data?.message,
      })
      state_.loading = false
    })
  }

  const getListCreditNotes = debounce(async () => {
    state_.loading = true
    let sellerId
    const statusTemp = 'REQUESTED,APPROVED,CANCELED,REJECTED'
    const businessId = route.app._route.query.business_id || (await getBusinessId())

    await getSeller({
      businessId,
      params: { warehouse_id: route.app._route.query.warehouse_id || state_.selectedWarehouse },
    })
    .then(async ({ data: { data: response } }) => {
      sellerId = response?.[0]?.seller_id
      state_.dataSeller = response?.[0]
      await getCreditNotes({
        business_id: businessId,
        params: {
          limit: state_.pagination.size,
          page: page.value,
          seller_id: sellerId,
          search: route.app._route.query.search || undefined,
          status: route.app._route.query.status || statusTemp,
        },
      })
      .then(({ data: { data: response } }) => {
        state_.dataListCreditNotes = response.contents
        state_.pagination.total_items = response.total_elements
      })
      .catch(err => {
        state_.dataPayment = []
        console.error(err)
      })
      .finally(() => state_.loading = false)
    })
  }, 500)

  const changePage = value => {
    route.push({
      query: {
        ...route.app._route.query,
        page: value,
        search: undefined,
        sort: undefined,
      },
    })
  }

  const handleWarehouse = e => {
    console.log({e})
    state_.selectedWarehouse = e
  }

  return {
    ...toRefs(state_),
    changePage,
    handleWarehouse,
    page,
    handleConfirm,
    handleShowModal,
    handleProcess,
    handleReject,
    handleCancel,
    getListCreditNotes,
  }
}
