<template>
  <div>
    <a-modal
      :visible="showModal"
      :footer="false"
      class="modal-retur"
      @cancel="$emit('handleModal', false, type, {})"
    >
      <h3 class="d-flex">
        <div>
          <a-tag
            :key="dataDetail?.status"
            :color="dataDetail?.status === 'REQUESTED' ? 'blue' : 
              dataDetail?.status === 'APPROVED' ? 'green' : 
              dataDetail?.status === 'CANCELED' ? 'pink' : 'red'"
          >
            Status: {{ getLabel(dataDetail?.status) }}
          </a-tag>
        </div>
        <div style="text-align: center; width: 80%;">
          Form Request Credit Notes
        </div>
      </h3>
      <hr />
      <div class="d-flex mt-2">
        <a-col :span="5" style="color: #6C757D">
          No. Credit Notes
        </a-col>
        <a-col :span="4" style="color: #212529">
          {{ dataDetail?.cn_number }}
        </a-col>
      </div>
      <div class="d-flex mt-2">
        <a-col :span="5" style="color: #6C757D">
          Nama Distributor
        </a-col>
        <a-col :span="8" style="color: #212529">
          {{ dataDetail?.seller_name }}
        </a-col>
      </div>
      <div class="d-flex mt-2">
        <a-col :span="5" style="color: #6C757D">
          Tanggal
        </a-col>
        <a-col :span="4" style="color: #212529">
          {{ $moment(dataDetail?.created_at).format('DD MMM YYYY') }}
        </a-col>
      </div>

      <!-- DATA --> <!-- LIST -->
      <div
        class="d-flex h-100 rounded font-weight-bold mt-2"
        :class="downloading ? 'pointer-no':''"
      >
        <a-icon :type="downloading ? 'loading':'download'" class="text-danger h2 ml-auto" />
      </div>
      <div class="list">
        <a-row :gutter="24" class="d-flex">
          <a-col :span="8">
            <div class="d-flex mt-2 justify-content-center" style="color: #6C757D">
              Nama
            </div>
            <hr />
            <div class="d-flex mt-2 justify-content-center" style="color: #212529">
              Koin Kino
            </div>
          </a-col>
          <a-col :span="8">
            <div class="d-flex mt-2 justify-content-center" style="color: #6C757D">
              Koin Penarikan
            </div>
            <hr />
            <div class="d-flex mt-2 justify-content-center" style="color: #212529">
              {{ toCurrency(dataDetail?.coin_value) }}
            </div>
          </a-col>
          <a-col :span="8">
            <div class="d-flex mt-2 justify-content-center" style="color: #6C757D">
              Rupiah
            </div>
            <hr />
            <div class="d-flex mt-2 justify-content-center" style="color: #212529">
              Rp. {{ toCurrency(dataDetail?.currency_value) }}
            </div>
          </a-col>
        </a-row>
        <div class="d-flex mt-3">
          <div class="ml-auto mr-3" style="color: #6C757D">
            Total
          </div>
          <div style="font-weight: bold">
            Rp. {{ toCurrency(dataDetail?.currency_value) }}
          </div>
        </div>
        <div class="d-flex justify-content-end mt-5">
          <a-button
            v-if="$store.state.user.restriction_base?.toLowerCase() !== 'business' && $store.state.user.role_name?.toLowerCase() !== 'principle' && dataDetail?.status === 'REQUESTED'"
            class="inbound__button mx-2 ml-auto"
            @click="showReject(true, 'cancel')"
          >
            Cancel
          </a-button>
          <a-button
            v-if="$store.state.user.restriction_base?.toLowerCase() === 'business' && $store.state.user.role_name?.toLowerCase() === 'principle' && dataDetail?.status === 'REQUESTED'"
            class="inbound__button mx-2 ml-auto"
            @click="showReject(true, 'tolak')"
          >
            Tolak
          </a-button>
          <a-button
            v-if="$store.state.user.restriction_base?.toLowerCase() === 'business' && $store.state.user.role_name?.toLowerCase() === 'principle' && dataDetail?.status === 'REQUESTED'"
            type="primary"
            :loading="loading"
            @click="showUpdate(true)"
          >
            Terima
          </a-button>
        </div>
      </div>
    </a-modal>
    <a-modal
      :visible="visibleReject"
      :footer="false"
      :closable="false"
    >
      <div class="text-center">
        <Question class="mx-3 mb-3 mt-3" />
      </div>
      <h3 class="text-center mb-3">
        {{ isType === 'tolak' ? 'Tolak Pengajuan' : 'Batalkan Pengajuan' }}
      </h3>
      <div class="mt-4">
        <span> {{ isType === 'tolak' ? 'Alasan Penolakan' : 'Alasan Pembatalan' }} </span>
        <a-textarea
          v-model="isReason"
          placeholder="Masukan Alasan"
          :auto-size="{ minRows: 5, maxRows: 5 }"
          @change="changeReason"
        />
      </div>
      <div class="text-center mt-5">
        <a-button class="inbound__button mx-2 ml-auto" @click="showReject(false)">
          Cancel
        </a-button>
        <a-button type="primary" :loading="loading" @click="$emit(isType === 'tolak' ? 'handleReject' : 'handleCancel', dataDetail.id, isReason)">
          Ya, {{ isType === 'tolak' ? 'Tolak' : 'Batalkan' }}
        </a-button>
      </div>
    </a-modal>
    <a-modal
      :visible="visibleUpdate"
      :footer="false"
      :closable="false"
    >
      <div class="text-center">
        <Question class="mx-3 mb-3 mt-3" />
      </div>
      <h3 class="text-center mb-3">
        Ubah Status Credit Notes
      </h3>
      <div class="text-center mt-4">
        <span style="color: #6C757D"> Apakah Anda yakin ingin menyelesaikan pengajuan Credit Notes ini? </span>
      </div>
      <div class="text-center mt-5">
        <a-button class="inbound__button mx-2 ml-auto" @click="showUpdate(false)">
          Batalkan
        </a-button>
        <a-button type="primary" :loading="loading" @click="handleRequest(dataDetail.id)">
          Ya, Selesaikan
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Question from '@/components/Icons/Question.vue'

export default {
  name: 'ModalDetail',
  components: {
    Question,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    downloading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data: function() {
    return {
      columns: [
        {
          title: 'Nama',
          dataIndex: 'catalog_title',
          key: 'catalog_title',
          ellipsis: true,
          class: 'text-center',
        },
        {
          title: 'Poin Penarikan',
          dataIndex: 'return_coin',
          key: 'return_coin',
          class: 'text-center',
        },
        {
          title: 'Rupiah',
          dataIndex: 'subtotal_approved',
          key: 'subtotal_approved',
          ellipsis: true,
          class: 'text-center',
          customRender: value => {
            const temp = value && this.toCurrency(value)
            if (temp) return 'Rp. ' + temp
            else return '-'
          },
        },
      ],
      dataDetail: {},
      dataApprove : [],
      visibleReject: false,
      visibleUpdate: false,
      isReason: '',
      isType: '',
    }
  },
  watch: {
    row: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.dataDetail = value
      },
    },
    showModal: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value === false) {
          this.visibleReject = value
          this.visibleUpdate = value
          this.isReason = ''
          this.isType = ''
        }
      },
    },
  },
   methods: {
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    getLabel(label) {
      if (label === 'REQUESTED') {
        return 'Pengajuan'
      } else if (label === 'APPROVED') {
        return 'Disetujui'
      } else if (label === 'REJECTED') {
        return 'Ditolak'
      } else if (label === 'CANCELED') {
        return 'Dibatalkan'
      }
    },
    handleRequest(id) {
        this.$emit('handleConfirm', id)
    },
    showReject(param, paramType) {
      this.visibleReject = param
      this.isType = paramType
    },
    showUpdate(param) {
      this.visibleUpdate = param
    },
    changeReason(param) {
      this.isReason = param.target.value
    },
    handleChange(e, param) {
      const newItem = this.dataDetail.return_items.map(item => {
        const temp = {
          ...item,
          approved_item_qty: item.id === param ? Number(e.target.value) : item.approved_item_qty,
        }
        return temp
      })
      const payload = newItem.map(item => {
        const temp = {
          id: item.id,
          approved_item_qty: item.id === param ? Number(e.target.value) : item.approved_item_qty,
          notes_item: item.request_reason,
        }
        return temp
      })
      this.dataDetail.return_items = newItem
      this.dataApprove = payload
      console.log({e: e, param, dataDetail: this.dataDetail, newItem, payload})

    },
  },
}
</script>

<style lang="scss">
.modal-retur {
  .list {
  border-radius: 5px;
  }
  .header-list {
    font-weight: 900;
    background: #E7ECEF;
    border-radius: 5px ;
    padding: 10px;
  }
  .data-list {
    padding: 5px 0;
    margin: 10px 0;
    background: #fff;
    border-radius: 5px ;
  }

  // ANT DESIGN
  .ant-modal {
    width: 75% !important;
  }
  .inbound__button {
    color: #ff0854 !important;
    background: #fff !important;
    border-color: #ff0854 !important;
  }
}
.inbound__button {
    color: #ff0854 !important;
    background: #fff !important;
    border-color: #ff0854 !important;
  }
</style>